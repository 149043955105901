import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements AfterViewInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public vpScroller: ViewportScroller,
    tags: PageTagsService
  ) {
    tags.handlePage('about');
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this.vpScroller.setOffset([0, 78]);
      if (!this.activatedRoute.snapshot.fragment) {
        this.vpScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
