import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NavbarComponent } from './navbar/navbar.component';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'IFI';
  year = new Date().getFullYear();
  gapHeightPx: number = 0;

  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-MHVZEKBTHM', { page_path: event.urlAfterRedirects });
      }
    });
  }

  @ViewChild('navbar') _navbar!: NavbarComponent;

  ngAfterViewInit(): void {
    setTimeout((_: any) => (this.gapHeightPx = this._navbar.height));
  }
}
