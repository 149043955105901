import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { SearchForm } from '../home.component';

@Component({
  selector: 'app-home-search-form',
  template: `
    <form #searchForm="ngForm" (ngSubmit)="submitSearchForm(searchForm)">
      <div class="d-flex">
        <div class="flex-fill mx-2">
          <input
            type="text"
            name="searchText"
            [(ngModel)]="searchText"
            class="form-control"
            [ngClass]="{
              'is-invalid': searchForm.submitted && searchForm.invalid
            }"
            (keyup.enter)="submitSearchForm(searchForm)"
            placeholder="Zakat al-maal, ..."
            required
          />
          <div
            *ngIf="searchForm.invalid && searchForm.submitted"
            class="invalid-feedback"
          ></div>
        </div>
        <div class="mx-2">
          <button type="submit" class="btn btn-primary">Rechercher</button>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['./home-search-form.component.scss'],
})
export class HomeSearchFormComponent implements OnInit {
  searchText = '';

  @Input() clearForm$!: Observable<void>;

  @Output() searchFormSubmitted: EventEmitter<SearchForm> = new EventEmitter();

  @ViewChild('searchForm')
  private _searchForm!: NgForm;

  groupCategoryFn = (_: any) => 'Toutes les catégories';

  ngOnInit(): void {
    this.clearForm$.subscribe(() => {
      this._searchForm.resetForm();
    });
  }

  submitSearchForm(searchForm: NgForm) {
    if (searchForm.invalid) {
      searchForm.controls['searchText'].markAsTouched();
      return;
    }
    this.searchFormSubmitted.emit({ text: this.searchText, categoryIds: [] });
  }
}
