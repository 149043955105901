import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, concat, of, NEVER } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../app.model';

@Component({
  selector: 'app-category-cloud',
  template: `
    <div
      *ngIf="selectedItemSource$ | async as selectedItem"
      class="d-flex flex-column"
    >
      <div class="d-flex justify-content-center">
        <h2 class="font-weight-bold">Catégories</h2>
      </div>
      <div class="d-flex flex-wrap justify-content-center">
        <h5 class="mr-1" *ngFor="let c of categories">
          <a
            [ngClass]="
              'badge ' +
              (selectedItem.value?.length === 1 &&
              selectedItem.value?.includes(c.id)
                ? 'badge-primary'
                : 'badge-secondary')
            "
            role="button"
            (click)="onClick([c.id])"
            >{{ c.name }}</a
          >
        </h5>
      </div>
      <h4 class="d-flex justify-content-center mt-1">
        <a
          [ngClass]="
            'badge ' +
            (selectedItem.value && selectedItem.value.length === 0
              ? 'badge-primary'
              : 'badge-secondary')
          "
          role="button"
          (click)="onClick(null)"
          >Publications à l'affiche</a
        >
      </h4>
      <h4 class="d-flex justify-content-center mt-1">
        <a
          [ngClass]="
            'badge ' +
            (selectedItem.value?.length === categories?.length
              ? 'badge-primary'
              : 'badge-secondary')
          "
          role="button"
          (click)="onClick(allCategoryIds())"
          >Toutes les publications</a
        >
      </h4>
    </div>
  `,
  styleUrls: ['./category-cloud.component.scss'],
})
export class CategoryCloudComponent implements OnInit {
  @Input()
  categories: Category[] | null = null;

  @Input()
  externalSelectionChanges$!: Observable<number[] | null>;

  @Output()
  itemClicked = new EventEmitter<number[] | null>();

  // null : none (form results, publication-detail)
  // []   : sticky
  // [x]  : cat id
  // ----
  // we have to wrap the value in an object because this stream toggles the main ngIf of the template,
  // and [] (none selected) is falsy, but we want to show the widget when value is 0
  selectedItemSource$!: Observable<{ value: number[] | null }>;

  constructor() {}

  ngOnInit(): void {
    this.selectedItemSource$ = concat(
      of(null),
      this.externalSelectionChanges$ || NEVER,
      this.itemClicked
    ).pipe(map((v) => ({ value: v })));
  }

  allCategoryIds(): number[] {
    return this.categories?.map((c) => c.id) || [-1];
  }

  onClick(x: number[] | null): void {
    this.itemClicked.emit(x);
  }
}
