<div class="bg-azhar mb-5">
  <div class="d-flex justify-content-center overlay-primary pt-2">
    <div class="col-lg-9">
      <img class="banner" src="assets/img/ifi_text.png" />
    </div>
  </div>
</div>
<div class="d-flex justify-content-center">
  <div class="d-flex flex-column col-md-9">
    <div class="d-flex justify-content-center">
      <div class="w-100 mb-5">
        <h1>Nous soutenir</h1>
        <br />
        <p class="text-justify donation-p">
          Chers visiteurs,
          <br /><br />
          Nous sommes ravis de vous offrir cette plateforme de
          questions-réponses, accessible à tous et sans publicité intrusive.
          Depuis le lancement de notre site internet, nous avons travaillé dur
          pour offrir un service de qualité et impartial, qui répond aux besoins
          de chacun.
          <br /><br />
          Cependant, maintenir une telle plateforme n'est pas facile ni bon
          marché. En plus des coûts d'hébergement des serveurs, nous consacrons
          beaucoup de temps et d'énergie pour répondre à toutes les questions
          posées, et nous avons besoin de votre soutien pour continuer à offrir
          ce service. Nous nous appuyons donc sur la générosité de notre
          communauté pour nous aider à couvrir les frais d'hébergement des
          serveurs et les coûts de fonctionnement.
          <br /><br />
          Si vous trouvez notre travail utile et que vous souhaitez nous aider à
          maintenir notre indépendance, vous pouvez soutenir notre projet en
          faisant un don. Chaque don, quelle que soit sa taille, est extrêmement
          apprécié et nous aidera à maintenir notre service gratuit et
          impartial.
          <br /><br />
          En faisant un don, vous aiderez également à assurer que notre équipe
          de réponse aux questions ait suffisamment de temps pour fournir des
          réponses précises et complètes, et pour continuer à améliorer la
          qualité de notre service.
          <br /><br />
          Nous vous remercions pour votre soutien continu et pour votre aide à
          faire de l’IFI un lieu sûr, impartial et de qualité pour répondre à
          toutes vos questions.
        </p>
        <p class="text-right donation-signature">
          L'équipe de l'Institut Francophone d'Iftaa
        </p>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="d-flex col-8 col-lg-3">
        <form
          #donateForm="ngForm"
          class="flex-fill"
          (ngSubmit)="submitDonateForm(donateForm)"
        >
          <div class="form-group row my-3">
            <div class="col px-0">
              <input
                id="amountInput"
                name="amountInput"
                class="form-control"
                type="number"
                min="1"
                placeholder="Montant"
                [(ngModel)]="amount"
                [ngClass]="{
                  'is-invalid': donateForm.submitted && donateForm.invalid
                }"
                (keyup.enter)="submitDonateForm(donateForm)"
                [disabled]="disableForm"
                required
              />
              <div
                *ngIf="donateForm.invalid && donateForm.submitted"
                class="invalid-feedback"
              >
                Montant invalide
              </div>
            </div>
            <label
              class="col-form-label font-weight-bold ml-3"
              for="amountInput"
              >EUR</label
            >
          </div>
          <div class="row">
            <div class="col px-0 btn-container">
              <button
                type="submit"
                class="w-100 btn btn-primary"
                [disabled]="disableForm"
              >
                {{ !disableForm ? "Faire un don" : "Un instant ..." }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #errorModal let-modal>
  <h4 class="modal-header">Erreur</h4>
  <div class="modal-body">
    <p [innerHtml]="'_Donate_ERROR_UNKNOWN_' | translate | safe: 'html'"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
      Ok
    </button>
  </div>
</ng-template>
