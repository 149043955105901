import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CguComponent } from './cgu/cgu.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PublicationDetailComponent } from './publication-detail/publication-detail.component';
import { HomeComponent } from './home/home.component';
import { QuestionComponent } from './question/question.component';
import { DonateComponent } from './donate/donate.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DonateFeedbackComponent } from './donate-feedback/donate-feedback.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'publication/:id', component: PublicationDetailComponent },
  { path: 'question', component: QuestionComponent },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'about', component: AboutComponent },
  { path: 'cgu', component: CguComponent },
  { path: 'donate', component: DonateComponent },
  { path: 'donate/feedback', component: DonateFeedbackComponent },
  { path: 'contact', component: ContactComponent },
  { path: '404', pathMatch: 'full', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
