import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../service/config.service';
import * as marked from 'marked';
import { ViewportScroller } from '@angular/common';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-cgu',
  template: `
    <div class="d-flex justify-content-center mt-5">
      <div class="col-md-9">
        <p
          id="cgu"
          [innerHTML]="
            cgu | MarkdownToHtml: { renderer: customMdrenderer } | safe: 'html'
          "
        ></p>
      </div>
    </div>
  `,
  styleUrls: ['./cgu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CguComponent implements AfterViewInit {
  appDomainName: string;
  cgu: string;
  customMdrenderer: MarkedRenderer;

  constructor(
    config: ConfigService,
    tags: PageTagsService,
    private _vpScroller: ViewportScroller
  ) {
    tags.handlePage('cgu');
    this.appDomainName = config.env().appDomainName;
    this.cgu = makeCgu(this.appDomainName);
    this.customMdrenderer = new marked.Renderer();
    this.customMdrenderer.link = (_, __, text) => text;
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });
  }
}

let makeCgu = (url: string) => `
## Conditions générales d'utilisation

En vigueur au 1er Janvier ${new Date().getFullYear()}.

Les présentes Conditions Générales d'Utilisation (dites « CGU ») ont pour objet l'encadrement
juridique des modalités de mise à disposition du site et des services par l'Institut Francophone
d'Iftaa (dit « IFI ») et de définir les conditions d'accès et d'utilisation
des services par « l'Utilisateur ».

Les présentes CGU sont accessibles sur le site à la rubrique « CGU ».

Toute utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes
CGU par l'utilisateur. Lors de la soumission d'une question via le formulaire, chaque utilisateur
accepte expressément les présentes CGU en cochant la case précédant le texte suivant :
« J'accepte les conditions générales d'utilisation ».

En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de
renoncer à l'accès des services proposés par le site.

${url} se réserve le droit de modifier unilatéralement et à tout moment le contenu
des présentes CGU.

### ARTICLE 1 : Les mentions légales

L'édition du site ${url} est assurée par l'association Institut Asharite,
immatriculée au RNA sous le numéro : W691087853,
dont le siège social est situé 1 rue Jacques Prévert - 69140 Rilleux-la-Pape.
Adresse e-mail : contact@institut-francophone-iftaa.com.

Le Directeur de la publication est : Max Frery.

L'hébergeur du site ${url} est la société OVH, dont le siège social est situé
au 2 rue Kellermann - 59100 Roubaix.


### ARTICLE 2 : Accès au site

Le site ${url} permet à l'Utilisateur un accès gratuit au service suivant :
consultation juridique.

Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous
les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels,
connexion Internet, etc.) sont à sa charge.

Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou
serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas
la responsabilité de ${url}.
Dans ces cas, l'Utilisateur accepte ainsi ne pas tenir rigueur à l'éditeur de toute interruption
ou suspension de service, même sans préavis.

L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l'adresse email
de l'éditeur communiqué à l'ARTICLE 1.

### ARTICLE 3 : Collecte des données

Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le
respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique,
aux fichiers et aux libertés.

En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un
droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
L'Utilisateur exerce ce droit par mail à l'adresse email communiquée à l'ARTICLE 1.

### ARTICLE 4 : Propriété intellectuelle

Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet
d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit
d'auteur.

L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction,
publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans
un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est
strictement interdite.

Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans
l'autorisation expresse de l'exploitant du site Internet constituerait une contrefaçon
sanctionnée par l'article L 335-2 et suivants du Code de la propriété intellectuelle.

Il est rappelé conformément à l'article L122-5 du Code de propriété intellectuelle que l'Utilisateur
qui reproduit, copie ou publie le contenu protégé doit citer l'auteur et sa source.

### ARTICLE 5 : Responsabilité

Les sources des informations diffusées sur le site ${url} sont réputées
fiables mais le site ne garantit pas qu'il soit exempt de défauts, d'erreurs ou d'omissions.

Les informations communiquées sont présentées à titre indicatif et général sans valeur
contractuelle. Malgré des mises à jour régulières, le site ${url} ne peut être
tenu responsable de la modification des dispositions administratives et juridiques survenant après la
publication. De même, le site ne peut être tenu responsable de l'utilisation et de l'interprétation
de l'information contenue dans ce site.

Le site ${url} ne peut être tenu pour responsable d'éventuels virus qui
pourraient infecter l'ordinateur ou tout matériel informatique de l'Internaute, suite à une
utilisation, à l'accès, ou au téléchargement provenant de ce site.

La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
insurmontable d'un tiers.

### ARTICLE 6 : Liens hypertextes

Des liens hypertextes peuvent être présents sur le site. L'Utilisateur est informé qu'en cliquant
sur ces liens, il sortira du site ${url} Ce dernier n'a pas de contrôle sur
les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de
leur contenu.

### ARTICLE 7 : Cookies

L'Utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer
automatiquement sur son logiciel de navigation. Les cookies sont de petits fichiers stockés
temporairement sur le disque dur de l'ordinateur de l'Utilisateur par votre navigateur et qui sont
nécessaires à l'utilisation du site ${url}. Les cookies ne contiennent pas
d'information personnelle et ne peuvent pas être utilisés pour identifier quelqu'un. Un cookie
contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à
la fin de la visite de l'Utilisateur, d'autres restent.

L'information contenue dans les cookies est utilisée pour améliorer le site ${url}.
En naviguant sur le site, L'Utilisateur les accepte. L'Utilisateur doit toutefois donner son
consentement quant à l'utilisation de certains cookies.
A défaut d'acceptation, l'Utilisateur est informé que certaines fonctionnalités ou pages risquent
de lui être refusées. L'Utilisateur pourra désactiver ces cookies par l'intermédiaire des
paramètres figurant au sein de son logiciel de navigation.

### ARTICLE 8 : Droit applicable et juridiction compétente

La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un
litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.

Pour toute question relative à l'application des présentes CGU, vous pouvez joindre l'éditeur aux
coordonnées inscrites à l'ARTICLE 1.
`;
