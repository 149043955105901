import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Category } from '../app.model';
import { ApiService } from '../service/api.service';
import { NewIstiftaaForm } from '../service/request';

import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  istiftaa: NewIstiftaaForm = <NewIstiftaaForm>{};
  categories: Category[] = [];
  cgu: boolean = false;
  ethics: boolean = false;

  staged: boolean = false;
  disableBtn: boolean = false;

  errorCode?: string = undefined;

  @ViewChild('errorModal') private errorModalTemplate!: TemplateRef<any>;

  constructor(
    private _api: ApiService,
    private _modalService: NgbModal,
    private _vpScroller: ViewportScroller,
    private _recaptchaV3Service: ReCaptchaV3Service,
    tags: PageTagsService
  ) {
    tags.handlePage('question');
  }

  ngOnInit(): void {
    this._api
      .getCategories()
      .subscribe((resp) => (this.categories = resp), console.error);
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });

    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy(): void {
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    if (
      form.value.category &&
      form.value.email &&
      form.value.content &&
      form.value.cgu
    ) {
      this.disableBtn = true;

      this._recaptchaV3Service
        .execute('importantAction')
        .subscribe((_) => {}, console.error);

      this._api.stageIstiftaa(this.istiftaa).subscribe((resp) => {
        this.istiftaa = <NewIstiftaaForm>resp;
        this.staged = true;
        this.disableBtn = false;
        this._vpScroller.scrollToPosition([0, 0]);
      }, this.handleSendError.bind(this));
    }
  }

  private handleSendError(err: any): void {
    console.error(err);
    this.errorCode = err?.error.message || 'ERROR_UNKNOWN';
    this._modalService
      .open(this.errorModalTemplate, { size: 'lg' })
      .result.then(
        (_) => (this.disableBtn = false),
        (_) => (this.disableBtn = false)
      );
  }
}
