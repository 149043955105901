import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface Config {
  env: EnvConfig;
}

export interface EnvConfig {
  appDomainName: string;
  apiBaseUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config!: Config;

  load(): Observable<Config> {
    return from(fetch('assets/config/env.json')).pipe(
      switchMap((r) => r.json()),
      map((r) => ({ env: r } as Config)),
      tap((r) => (this.config = r))
    );
  }

  env(): EnvConfig {
    return this.config.env;
  }
}
