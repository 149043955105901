import { Component, Input, OnInit } from '@angular/core';
import { Category, Publication } from 'src/app/app.model';
import { CategoriesService } from 'src/app/service/categories.service';

@Component({
  selector: 'app-publication-card',
  templateUrl: './publication-card.component.html',
  styleUrls: ['./publication-card.component.scss'],
})
export class PublicationCardComponent implements OnInit {
  @Input() publication!: Publication;
  publicationCategory!: Category;
  categoryImg!: string;

  constructor(private _categories: CategoriesService) {}

  ngOnInit(): void {
    this._categories
      .getById(this.publication.categoryId)
      .subscribe((c) => (this.publicationCategory = c), console.error);
    this._categories
      .getImage(this.publication.categoryId, 'small')
      .subscribe((i) => (this.categoryImg = i), console.error);
  }
}
