import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements AfterViewInit {
  disableForm = false;
  sentDone = false;

  @ViewChild('errorModal') private errorModalTemplate!: TemplateRef<any>;

  constructor(
    private _api: ApiService,
    private _modalService: NgbModal,
    private _vpScroller: ViewportScroller,
    tags: PageTagsService
  ) {
    tags.handlePage('contact');
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });
  }

  submitContactForm(contactForm: NgForm): void {
    if (contactForm.invalid) {
      contactForm.control.markAllAsTouched();
      return;
    }
    this.disableForm = true;
    this._api
      .contact(
        contactForm.value.senderEmail,
        contactForm.value.subject,
        contactForm.value.message
      )
      .subscribe(() => (this.sentDone = true), this.handleSendError.bind(this));
  }

  private handleSendError(err: any): void {
    console.error(err);
    this._modalService
      .open(this.errorModalTemplate, { size: 'lg' })
      .result.then(
        (_) => (this.disableForm = false),
        (_) => (this.disableForm = false)
      );
  }
}
