import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionComponent } from './question/question.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ConfigService } from './service/config.service';
import { ApiService } from './service/api.service';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeSearchFormComponent } from './home/home-search-form/home-search-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HomeSearchResultsComponent } from './home/home-search-results/home-search-results.component';
import { PublicationCardComponent } from './home/home-search-results/publication-card/publication-card.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { CguComponent } from './cgu/cgu.component';
import { PublicationDetailComponent } from './publication-detail/publication-detail.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { SafePipe } from './pipe/safe.pipe';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from '../environments/environment';
import { CategoryCloudComponent } from './category-cloud/category-cloud.component';
import { DonateComponent } from './donate/donate.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DonateFeedbackComponent } from './donate-feedback/donate-feedback.component';
import { ContactComponent } from './contact/contact.component';
import { TazkiyaCardComponent } from './about/tazkiya-card/tazkiya-card.component';

const DEFAULT_LOCALE = 'fr';

moment.locale(DEFAULT_LOCALE);

// factories
function translateHttpLoaderFactory(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(http),
    './assets/i18n/',
    '.json?v=' + Date.now()
  );
}

function customTranslateServiceFactory(
  translateService: TranslateService
): () => Observable<any> {
  return (): Observable<any> => {
    translateService.setDefaultLang(DEFAULT_LOCALE);
    return translateService.use(DEFAULT_LOCALE);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    QuestionComponent,
    HomeComponent,
    HomeSearchFormComponent,
    HomeSearchResultsComponent,
    AboutComponent,
    PublicationCardComponent,
    CguComponent,
    PublicationDetailComponent,
    ConfirmComponent,
    SafePipe,
    CategoryCloudComponent,
    DonateComponent,
    PageNotFoundComponent,
    DonateFeedbackComponent,
    ContactComponent,
    TazkiyaCardComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    MarkdownToHtmlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    RecaptchaV3Module,
    AppRoutingModule,
    NgbModule,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: customTranslateServiceFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    ApiService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
