import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ConfigService } from './config.service';
import { Publication } from '../app.model';

export type Page =
  | 'home'
  | 'about'
  | 'question'
  | 'donate'
  | 'publication-detail'
  | 'contact'
  | 'cgu';

@Injectable({
  providedIn: 'root',
})
export class PageTagsService {
  private static SITE_TITLE = 'Institut Francophone d’Iftaa';

  private _applyCallbacks = new Map<Page, (...args: any[]) => void>([
    ['home', this._handleHomePage],
    ['about', this._handleAboutPage],
    ['question', this._handleQuestionPage],
    ['donate', this._handleDonatePage],
    ['publication-detail', this._handlePublicationDetailsPage],
    ['contact', this._handleContactPage],
    ['cgu', this._handleCguPage],
  ]);

  private _appDomainName: string;

  constructor(
    private _title: Title,
    private _meta: Meta,
    config: ConfigService
  ) {
    this._appDomainName = config.env().appDomainName;
  }

  handlePage(page: Page, ...args: any) {
    this._handleAppTags();
    let cb = this._applyCallbacks.get(page);
    if (cb) {
      cb.call(this, args[0]);
    } else {
      console.warn(`Cannot apply tags for page '${page}'`);
    }
  }

  private _handleAppTags() {
    this._addTitleTags(PageTagsService.SITE_TITLE);

    this._addTag({ charset: 'utf-8' });
    this._addTag({ httpEquiv: 'content-language', content: 'fr-fr' });
    this._addTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    });

    this._addTag({ name: 'msapplication-TileColor', content: '#2b5797' });
    this._addTag({ name: 'theme-color', content: '#ffffff' });

    this._addTag({ property: 'og:type', content: 'website' });
    this._addTag({ property: 'og:locale', content: 'fr_FR' });
    this._addTag({ property: 'og:site_name', content: 'IFI' });

    this._addUrlTags(`https://${this._appDomainName}`);

    this._addDescriptionTags(
      'Un institut d’Iftaa d’obédience malékite en langue française.'
    );

    this._addImageTags({
      url: `https://${this._appDomainName}/assets/img/preview.png`,
      mimetype: 'image/png',
      w: 1200,
      h: 630,
    });

    this._addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this._addTag({ property: 'twitter:site', content: '@ifi_iftaa' });
    this._addTag({ property: 'twitter:creator', content: '@ifi_iftaa' });
  }

  private _handleHomePage() {
    this._addTitleTags(`Accueil | ${PageTagsService.SITE_TITLE}`);
  }

  private _handleAboutPage() {
    this._addTitleTags(`A propos | ${PageTagsService.SITE_TITLE}`);
  }

  private _handleQuestionPage() {
    this._addTitleTags(`Poser une question | ${PageTagsService.SITE_TITLE}`);
  }

  private _handleDonatePage() {
    this._addTitleTags(`Nous soutenir | ${PageTagsService.SITE_TITLE}`);
  }

  private _handlePublicationDetailsPage(...args: any) {
    let pub = <Publication>args[0];
    this._addTitleTags(`${pub.subject} | ${PageTagsService.SITE_TITLE}`);
    this._addDescriptionTags(
      `${pub.istiftaaBody} | ${PageTagsService.SITE_TITLE}`
    );
    this._addImageTags({
      url: `https://${this._appDomainName}/api/category/${pub.categoryId}/image?size=large&raw=true`,
      mimetype: 'image/webp',
      w: 990,
      h: 470,
    });
  }

  private _handleContactPage() {
    this._addTitleTags(`Nous contacter | ${PageTagsService.SITE_TITLE}`);
  }

  private _handleCguPage() {
    this._addTitleTags(
      `Conditions générales d'utilisation | ${PageTagsService.SITE_TITLE}`
    );
  }

  private _addTitleTags(title: string) {
    this._title.setTitle(title);
    this._addTag({ property: 'og:title', content: title });
    this._addTag({ property: 'twitter:title', content: title });
  }

  private _addUrlTags(url: string) {
    this._addTag({ property: 'og:url', content: url });
    this._addTag({ property: 'twitter:url', content: url }); // not documented
    this._addTag({ property: 'twitter:domain', content: url });
  }

  private _addDescriptionTags(description: string) {
    this._addTag({ name: 'description', content: description });
    this._addTag({ property: 'og:description', content: description });
    this._addTag({ property: 'twitter:description', content: description });
  }

  private _addImageTags(imgInfo: {
    url: string;
    mimetype: string;
    w: number;
    h: number;
  }) {
    this._addTag({ property: 'og:image', content: imgInfo.url });
    this._addTag({
      property: 'og:image:alt',
      content: PageTagsService.SITE_TITLE,
    });
    this._addTag({ property: 'og:image:type', content: imgInfo.mimetype });
    this._addTag({ property: 'og:image:width', content: imgInfo.w.toString() });
    this._addTag({
      property: 'og:image:height',
      content: imgInfo.h.toString(),
    });
    this._addTag({ property: 'twitter:image', content: imgInfo.url });
  }

  private _addTag(def: MetaDefinition) {
    let sel = this._forgeAttrSelectorFromDef(def);
    sel && this._meta.getTag(sel)
      ? this._meta.updateTag(def, sel)
      : this._meta.addTag(def);
  }

  private _forgeAttrSelectorFromDef(def: MetaDefinition): string | undefined {
    let sel;
    if (def.property) {
      sel = `property='${def.property}'`;
    } else if (def.name) {
      sel = `name='${def.name}'`;
    } else {
      sel = undefined;
    }
    return sel;
  }
}
