<div class="row justify-content-center">
  <div class="col-8 pt-5">
    <div *ngIf="state$ | async as state">
      <div class="jumbotron jumbotron-fluid">
        <div>
          <p
            class="lead text-success text-center"
            [ngClass]="'text-' + (state === 'cancelled' ? 'danger' : 'success')"
            [innerHtml]="
              '_Donate_Feedback_' + state.toUpperCase() + '_'
                | translate
                | safe: 'html'
            "
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
