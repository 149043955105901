import { Injectable } from '@angular/core';

import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Category } from '../app.model';
import { ApiService } from './api.service';

export type ImageSize = 'small' | 'large';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private _categories$: ReplaySubject<Category[]> = new ReplaySubject(1);

  constructor(private _api: ApiService) {
    this._fetch();
  }

  private _fetch(): void {
    this._api
      .getCategories()
      .pipe(
        map((allCat) => {
          allCat.push(allCat.shift()!);
          return allCat;
        }),
        tap((allCat) => this._categories$.next(allCat))
      )
      .toPromise();
  }

  getAll(refetch?: boolean): Observable<Category[]> {
    if (refetch) this._fetch();
    return this._categories$;
  }

  getById(id: number): Observable<Category> {
    return this.getAll().pipe(
      mergeMap((allCats) => {
        let foundCat = allCats.filter((c) => c.id === id);
        return foundCat[0]
          ? of(foundCat[0])
          : throwError('Category ' + id + ' is unknown');
      })
    );
  }

  getImage(id: number, size: ImageSize): Observable<string> {
    return this._api.getCategoryImage(id, size);
  }
}
