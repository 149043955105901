import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router,
  convertToParamMap,
} from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Category, Publication } from '../app.model';
import { ApiService, PaginatedData } from '../service/api.service';
import { CategoriesService } from '../service/categories.service';
import { PageTagsService } from '../service/pagetags.service';

export interface SearchForm {
  text: string;
  categoryIds: number[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  categories$!: Observable<Category[]>;
  clearSearchForm$ = new Subject<void>();
  categorySelection$ = new ReplaySubject<number[]>(1);

  private _searchFormSubmittedSource$ = new Subject<SearchForm>();
  private _categoryChangedSource$ = new Subject<number[] | null>();
  private _viewMoreClickedSource$ = new Subject<boolean>();
  private _viewMorePageId = 0;

  lastSearchForm?: SearchForm;
  fetchedPublications$ = new BehaviorSubject<Publication[] | undefined>(
    undefined
  );

  displaySearchResults = false;
  hasMore$ = new BehaviorSubject<boolean>(false);

  private _searchFormSubmittedSubscription?: Subscription;
  private _categoryChangedSubscription?: Subscription;
  private _viewMoreClickedSubscription?: Subscription;

  constructor(
    private _api: ApiService,
    private _categoriesSvc: CategoriesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _vpScroller: ViewportScroller,
    tags: PageTagsService
  ) {
    tags.handlePage('home');
  }

  ngOnInit(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    this._viewMorePageId = 0;
    this.categories$ = this._categoriesSvc.getAll();

    this._route.queryParams
      .pipe(
        map((qp: Params) => {
          let pm = convertToParamMap(qp);
          return pm.getAll('categoryId').map((c) => Number(c));
        }),
        tap((catIds) => this.categorySelection$.next(catIds)),
        switchMap((catIds) => {
          let sticky = catIds.length == 0;
          return this.fetchPublications('', catIds, false, sticky).pipe(
            map((pubs) => ({ sticky, ...pubs }))
          );
        })
      )
      .subscribe((pubs) => {
        if (!pubs.sticky) {
          this.displaySearchResults = true;
          this._vpScroller.scrollToAnchor('results');
        }
      });

    this._searchFormSubmittedSubscription = this._searchFormSubmittedSource$
      .pipe(
        tap((_) => (this.displaySearchResults = true)),
        tap((_) => this.categorySelection$.next([-1])),
        switchMap((form) =>
          this.fetchPublications(form.text, form.categoryIds, false)
        )
      )
      .subscribe(() => this._vpScroller.scrollToAnchor('results'));

    this._categoryChangedSubscription = this._categoryChangedSource$
      .pipe(
        tap((_) => this.clearSearchForm$.next()),
        tap((catIds) => (this.displaySearchResults = catIds !== null)),
        switchMap((catId) =>
          this._router.navigate(['/home'], {
            queryParams: {
              categoryId: catId !== null ? catId : undefined,
            },
          })
        )
      )
      .subscribe(() => this._vpScroller.scrollToAnchor('results'));

    this._viewMoreClickedSubscription = this._viewMoreClickedSource$
      .pipe(
        withLatestFrom(this.categorySelection$),
        switchMap(([sticky, catSel]) =>
          this.fetchPublications(
            sticky ? undefined : this.lastSearchForm?.text,
            catSel,
            true,
            sticky
          )
        )
      )
      .subscribe(() => {});
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });
  }

  ngOnDestroy(): void {
    if (this._viewMoreClickedSubscription) {
      this._viewMoreClickedSubscription.unsubscribe();
    }
    if (this._categoryChangedSubscription) {
      this._categoryChangedSubscription.unsubscribe();
    }
    if (this._searchFormSubmittedSubscription) {
      this._searchFormSubmittedSubscription.unsubscribe();
    }
  }

  onSearchFormSubmitted(searchForm: SearchForm): void {
    this._viewMorePageId = 0;
    this.hasMore$.next(false);
    this.lastSearchForm = searchForm;
    this._searchFormSubmittedSource$.next(searchForm);
  }

  onCategoryChanged(catIds: number[] | null): void {
    this._viewMorePageId = 0;
    this.hasMore$.next(false);
    this._categoryChangedSource$.next(catIds);
  }

  onViewMoreClicked(sticky: boolean): void {
    this._viewMorePageId++;
    this._viewMoreClickedSource$.next(sticky);
  }

  fetchPublications(
    searchText: string = '',
    categoryIds: number[] = [],
    append: boolean = false,
    sticky: boolean = false
  ): Observable<PaginatedData<Publication>> {
    let params = {};
    params = {
      pageId: this._viewMorePageId,
      pageSize: 15,
    };
    if (categoryIds) {
      params = {
        categories: categoryIds,
        ...params,
      };
    }
    if (searchText) {
      params = {
        text: searchText,
        ...params,
      };
    }
    return (
      sticky
        ? this._api.getStickyPublications(params)
        : this._api.searchPublications(params)
    ).pipe(
      tap((page) => {
        let pubs = page.data;
        if (pubs) {
          this.hasMore$.next(this._viewMorePageId + 1 < page.pageCount);
          this.fetchedPublications$.next(
            append && this.fetchedPublications$.value && pubs
              ? this.fetchedPublications$.value.concat(pubs)
              : pubs
          );
        } else {
          this.fetchedPublications$.next(undefined);
        }
      }),
      catchError(() => {
        this.fetchedPublications$.next([]);
        this.hasMore$.next(false);
        this._viewMorePageId = 0;
        return EMPTY;
      })
    );
  }
}
