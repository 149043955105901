import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { NewIstiftaaForm } from './request';
import { Category, Publication } from '../app.model';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';

type SearchPublicationsResult = {
  id: number;
  subject: string;
  categoryId: number;
  answerDate: string;
  istiftaaBody: string;
  fatwaBody: string;
  tags?: string[];
};

export interface PaginatedData<T> {
  data?: T[];
  pageCount: number;
  totalResourceCount: number;
}

export type ImageSize = 'small' | 'large';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.baseUrl = this.config.env().apiBaseUrl;
  }

  getStickyPublications(params?: {
    pageId?: number;
    pageSize?: number;
  }): Observable<PaginatedData<Publication>> {
    return this.http
      .get<SearchPublicationsResult[]>(this.baseUrl + '/publication/sticky', {
        params,
        observe: 'response',
      })
      .pipe(mergeMap((resp) => this.buildPaginatedDataFromResponse(resp)));
  }

  searchPublications(params?: {
    text?: string;
    categories?: string[] | string;
    pageId?: number;
    pageSize?: number;
  }): Observable<PaginatedData<Publication>> {
    return this.http
      .get<SearchPublicationsResult[]>(this.baseUrl + '/publication/search', {
        params,
        observe: 'response',
      })
      .pipe(mergeMap((resp) => this.buildPaginatedDataFromResponse(resp)));
  }

  getPublication(id: number): Observable<Publication> {
    return this.http
      .get<SearchPublicationsResult>(this.baseUrl + '/publication/' + id)
      .pipe(map((res) => this._transformPublication(res)));
  }

  stageIstiftaa(istiftaa: NewIstiftaaForm) {
    return this.http.post<NewIstiftaaForm>(
      this.baseUrl + '/question/stage',
      istiftaa
    );
  }

  confirmIstiftaa(token: string) {
    let params: HttpParams = new HttpParams().set('token', token);
    return this.http.get(this.baseUrl + '/question/confirm', { params });
  }

  getCategories() {
    return this.http.get<Category[]>(this.baseUrl + '/category');
  }

  getCategoryImage(id: number, size: ImageSize) {
    return this.http.get(this.baseUrl + `/category/${id}/image?size=${size}`, {
      responseType: 'text',
    });
  }

  donate(amount: number): Observable<string> {
    return this.http.post<string>(this.baseUrl + `/donation`, amount);
  }

  contact(
    senderEmail: string,
    subject: string,
    message: string
  ): Observable<void> {
    return this.http.post<void>(this.baseUrl + `/contact`, {
      senderEmail,
      subject,
      message,
    });
  }

  private buildPaginatedDataFromResponse(
    resp: HttpResponse<SearchPublicationsResult[]>
  ): Observable<PaginatedData<Publication>> {
    if (resp.body == null) {
      return throwError('null body');
    }
    let pageCountHdr = resp.headers.get('X-Pagination-Page-Count');
    if (pageCountHdr == null) {
      return throwError('null pagination page count');
    }
    let resourceCountHdr = resp.headers.get('X-Pagination-Resource-Count');
    if (resourceCountHdr == null) {
      return throwError('null pagination resource count');
    }

    let data = resp.body;
    let pageCount = parseInt(pageCountHdr);
    let totalResourceCount = parseInt(resourceCountHdr);
    return of(
      this._transformPublicationsResult({ data, pageCount, totalResourceCount })
    );
  }

  private _transformPublicationsResult(
    resultPage: PaginatedData<SearchPublicationsResult>
  ): PaginatedData<Publication> {
    let data = resultPage.data?.map((i) => this._transformPublication(i));
    return {
      ...resultPage,
      // [] -> undefined (for cleaner handling in async pipe)
      data: data && data.length > 0 ? data : undefined,
    };
  }

  private _transformPublication(res: SearchPublicationsResult): Publication {
    return <Publication>{
      id: <number>res?.id,
      subject: res?.subject,
      categoryId: res?.categoryId,
      answerDate: moment.utc(res?.answerDate),
      istiftaaBody: res?.istiftaaBody,
      fatwaBody: res?.fatwaBody,
    };
  }
}
