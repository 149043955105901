<div class="d-flex justify-content-center mt-5">
  <div *ngIf="publication && categoryImg" class="col-md-8">
    <div class="row justify-content-center">
      <div class="col p-0">
        <img
          class="img-fluid rounded mx-auto d-block"
          [src]="'data:image/webp;base64,' + categoryImg | safe: 'url'"
          alt="{{ publicationCategory.name }}"
        />
      </div>
    </div>

    <div class="row mt-4 justify-content-center">
      <div class="col">
        <h1 id="subject">{{ publication.subject }}</h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col">
        <h6 id="date">{{ publication.answerDate.format("DD MMMM yyyy") }}</h6>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9">
        <div class="d-flex mt-4">
          <div class="col p-0">
            <h3 class="section-title">Question</h3>
          </div>
        </div>
        <div class="d-flex">
          <div class="col p-0">
            <p
              class="section-content"
              [innerHTML]="publication.istiftaaBody | safe: 'html'"
            ></p>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="col p-0">
            <h3 class="section-title">Réponse</h3>
          </div>
        </div>
        <div class="d-flex">
          <div class="col p-0">
            <p
              class="section-content"
              [innerHTML]="publication.fatwaBody | safe: 'html'"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-4">
        <app-category-cloud
          [categories]="categories$ | async"
          (itemClicked)="onCategoryChanged($event)"
        ></app-category-cloud>
      </div>
    </div>
  </div>
</div>
