import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivationEnd, Event, Router } from '@angular/router';
import { Subject, Subscription, merge } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  isCollapsed: boolean = true;
  height: number = 0;
  activatedRoute!: string;

  private _clickedOut$: Subject<undefined> = new Subject();

  @ViewChild('navbar') navbar!: ElementRef;
  @HostListener('document:click', ['$event']) private _(event: any) {
    if (!this.navbar.nativeElement.contains(event.target)) {
      this._clickedOut$.next();
    }
  }

  private _autoCollapseSubscription!: Subscription;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this._autoCollapseSubscription = merge(
      this._router.events.pipe(
        filter((e: Event): e is Event => e instanceof ActivationEnd),
        map((e) => <ActivationEnd>e),
        tap((e) => (this.activatedRoute = e.snapshot.url[0].path))
      ),
      this._clickedOut$
    ).subscribe((_) => (this.isCollapsed = true));
  }

  ngAfterViewInit(): void {
    this.height = this.navbar.nativeElement.offsetHeight;
  }

  ngOnDestroy(): void {
    if (this._autoCollapseSubscription) {
      this._autoCollapseSubscription.unsubscribe();
    }
  }
}
