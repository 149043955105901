<div class="row justify-content-center">
  <div class="col-8 pt-5">
    <div class="jumbotron jumbotron-fluid">
      <div *ngIf="confirmResult$ | async as res; else confirming">
        <p class="lead text-center">
          {{ "_Confirm_Result_" + res + "_" | translate }}
        </p>
      </div>

      <ng-template #confirming>
        <p class="lead text-center">
          {{ "_Confirm_In_Progress_" | translate }}
        </p>
      </ng-template>
    </div>
  </div>
</div>
