<div class="d-flex justify-content-center">
  <div *ngIf="!staged; else elseBlock" class="col-lg-9">
    <form #newIstiftaaForm="ngForm">
      <fieldset class="mt-5">
        <legend>Poser une question</legend>

        <div class="d-flex">
          <div class="col col-lg-4 form-group">
            <label for="email" class="form-label mt-4">
              <strong>Email : </strong><span class="text-danger">*</span></label
            >
            <input
              type="email"
              id="email"
              class="form-control"
              name="email"
              placeholder="exemple@email.com"
              name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              #email="ngModel"
              [ngClass]="{
                'is-invalid': newIstiftaaForm.submitted && email.invalid
              }"
              [(ngModel)]="istiftaa.mustaftiEmail"
              required
            />
            <div
              *ngIf="newIstiftaaForm.submitted && email.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="email.errors?.required">
                Veuillez saisir une adresse email
              </div>
              <div *ngIf="email.errors?.pattern">
                L'email que vous avez saisi n'est pas valide
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="col col-lg-4 form-group">
            <label for="category" class="form-label mt-4">
              <strong>Catégorie : </strong>
              <span class="text-danger">*</span>
            </label>
            <select
              type="text"
              id="category"
              class="form-control"
              name="category"
              #category="ngModel"
              [ngClass]="{
                'is-invalid': newIstiftaaForm.submitted && category.invalid
              }"
              [(ngModel)]="istiftaa.categoryId"
              required
            >
              <option
                *ngFor="let category of categories"
                [ngValue]="category.id"
              >
                {{ category.name }}
              </option>
            </select>
            <div
              *ngIf="newIstiftaaForm.submitted && category.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="category.errors?.required">
                Veuillez choisir une catégorie
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="col col-lg-6 form-group">
            <label for="subject" class="form-label mt-4">
              <strong>Titre :</strong></label
            >
            <input
              type="text"
              #subject="ngModel"
              class="form-control"
              id="subject"
              name="subject"
              maxlength="80"
              [(ngModel)]="istiftaa.subject"
            />
            <div class="d-flex justify-content-end">
              <small>({{ subject.value?.length || 0 }}/80)</small>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="col form-group">
            <label for="content" class="form-label mt-4">
              <strong>Votre demande : </strong>
              <span class="text-danger">*</span>
            </label>
            <textarea
              id="content"
              class="form-control"
              name="content"
              rows="3"
              minlength="10"
              maxlength="2000"
              #content="ngModel"
              [(ngModel)]="istiftaa.content"
              [ngClass]="{
                'is-invalid': newIstiftaaForm.submitted && content.invalid
              }"
              required
            ></textarea>
            <div
              *ngIf="newIstiftaaForm.submitted && content.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="content.errors?.required">
                Veuillez saisir un contenu
              </div>
              <div *ngIf="content.errors?.minlength">
                Le contenu doit au minimum contenir 10 caracthères
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <small>({{ content.value?.length || 0 }}/2000)</small>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="ml-3 col form-group">
            <input
              type="checkbox"
              id="cgu"
              class="g-4 form-check-input"
              name="cgu"
              [ngClass]="{ 'is-invalid': newIstiftaaForm.submitted && !cgu }"
              [(ngModel)]="cgu"
              required
            />
            <label class="form-check-label" for="cgu">
              J'accepte les
              <a routerLink="/cgu" target="_blank" rel="noopener"
                >conditions générales d'utilisation</a
              >
              <span class="text-danger"> *</span>
            </label>
            <div
              *ngIf="!cgu && newIstiftaaForm.submitted"
              class="invalid-feedback"
            >
              Veuillez accepter les conditions générales d'utilisation
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="ml-3 col form-group">
            <input
              type="checkbox"
              id="ethics"
              class="g-4 form-check-input"
              name="ethics"
              [ngClass]="{ 'is-invalid': newIstiftaaForm.submitted && !ethics }"
              [(ngModel)]="ethics"
              required
            />
            <label class="form-check-label" for="cgu">
              J'ai pris connaissance des
              <a
                routerLink="/about"
                fragment="mustafti"
                target="_blank"
                rel="noopener"
                >convenances du questionneur</a
              >
              <span class="text-danger"> *</span>
            </label>
            <div
              *ngIf="!ethics && newIstiftaaForm.submitted"
              class="invalid-feedback"
            >
              Veuillez prendre connaissance des convenances du questionneur
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-4 btn-container">
          <button
            class="g-recaptcha btn btn-primary"
            (click)="send(newIstiftaaForm)"
            [disabled]="disableBtn"
          >
            Envoyer
          </button>
        </div>
      </fieldset>
    </form>
  </div>
  <ng-template #elseBlock>
    <div class="d-flex justify-content-center">
      <div class="col-12" style="height: 40rem">
        <h1 class="mt-3 text-success">
          Votre question a été envoyée avec succès
        </h1>
        <a routerLink="/home">Retour à la page principale</a>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #errorModal let-modal>
  <h4 class="modal-header">Erreur</h4>
  <div class="modal-body">
    <p
      [innerHtml]="'_Question_' + errorCode + '_' | translate | safe: 'html'"
    ></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
      Ok
    </button>
  </div>
</ng-template>
