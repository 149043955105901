<div class="d-flex justify-content-center mt-5">
  <div class="d-flex flex-column col-md-9">
    <div *ngIf="!sentDone; else sent" class="d-flex justify-content-center">
      <div class="w-100 mb-5">
        <h1>Nous contacter</h1>
        <br />
        <p class="contact-p">
          Vous pouvez utiliser ce formulaire de contact pour soumettre toute
          suggestion d'amélioration ou autres questions concernant l'IFI.
        </p>
        <div class="alert alert-danger contact-p">
          Les questions d'ordre juridique envoyées via ce formulaire ne seront
          <strong>pas</strong> traitées, pour ces dernières, veuillez utiliser
          <a class="alert-link" routerLink="/question"
            >la page prévue à cet effet</a
          >.
        </div>
        <br />
        <form #contactForm="ngForm" (ngSubmit)="submitContactForm(contactForm)">
          <div class="form-group">
            <label for="senderEmail">
              <strong>Votre adresse email : </strong>
              <span class="text-danger">*</span>
            </label>
            <input
              ngModel
              id="senderEmail"
              name="senderEmail"
              #senderEmail="ngModel"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  senderEmail.invalid &&
                  (senderEmail.touched || senderEmail.dirty)
              }"
              type="email"
              placeholder="exemple@email.com"
              required
              email="true"
            />
            <div
              *ngIf="
                senderEmail.invalid &&
                (senderEmail.touched || senderEmail.dirty)
              "
              class="text-danger"
            >
              <div *ngIf="senderEmail.errors?.['required']">
                Veuillez saisir une adresse email
              </div>
              <div *ngIf="senderEmail.errors?.['email']">
                Veuillez saisir une adresse email valide
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="subject">
              <strong>Objet de votre message : </strong>
            </label>
            <input
              ngModel
              id="subject"
              name="subject"
              #subject="ngModel"
              class="form-control"
              type="text"
              placeholder="Proposition d'amélioration..."
              maxlength="80"
            />
          </div>
          <div class="form-group">
            <label for="message">
              <strong>Message : </strong>
              <span class="text-danger">*</span>
            </label>
            <textarea
              ngModel
              id="message"
              name="message"
              #message="ngModel"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  message.invalid && (message.touched || message.dirty)
              }"
              rows="10"
              required
              minlength="10"
              maxlength="1000"
            ></textarea>
            <div class="d-flex justify-content-end">
              <small
                >(<span>{{ message.value?.length || 0 }}</span
                >/1000)</small
              >
            </div>
            <div
              *ngIf="message.invalid && (message.touched || message.dirty)"
              class="text-danger"
            >
              <div *ngIf="message.errors?.['required']">
                Veuillez saisir un message
              </div>
              <div *ngIf="message.errors?.['minlength']">
                Veuillez saisir un message d'au moins 10 caractères
              </div>
            </div>
          </div>
          <div class="btn-container">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="disableForm"
            >
              {{ !disableForm ? "Envoyer " : "Un instant ..." }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <ng-template #sent>
      <div class="jumbotron jumbotron-fluid">
        <div>
          <p
            class="lead text-success text-center"
            [innerHtml]="'_Contact_Feedback_DONE_' | translate | safe: 'html'"
          ></p>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #errorModal let-modal>
  <h4 class="modal-header">Erreur</h4>
  <div class="modal-body">
    <p [innerHtml]="'_Contact_ERROR_UNKNOWN_' | translate | safe: 'html'"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
      Ok
    </button>
  </div>
</ng-template>
