<div
  *ngIf="publication && publicationCategory && categoryImg"
  class="d-flex flex-grow-1"
>
  <a routerLink="/publication/{{ publication.id }}" id="publication-link">
    <div id="border-double">
      <img
        class="img-home border-double rounded"
        [src]="'data:image/webp;base64,' + categoryImg | safe: 'url'"
        alt="{{ publicationCategory.name }} image"
        width="170px"
      />
    </div>
    <div class="flex-grow-1">
      <h3>{{ publication.subject }}</h3>
      <h6>{{ publication.answerDate.format("DD MMMM yyyy") }}</h6>
    </div>
  </a>
</div>
