<div class="bg-kairouan mb-5">
  <div class="d-flex justify-content-center overlay-primary pt-2">
    <div class="col-lg-9">
      <img class="banner" src="assets/img/ifi_text.png" />
    </div>
  </div>
</div>
<div class="d-flex flex-column justify-content-center">
  <section id="about" class="pt-0">
    <div>
      <h1>A propos de l'IFI</h1>
      <p>
        À la demande de son enseignant le shaykh AbdAllâh Penot, Ustâdh Maxime,
        enseignant ayant participé à la création de l’Institut Asharite,
        disposant de plusieurs autorisations de compréhension (ijâzâtu dirâya)
        dans les sciences religieuses et particulièrement dans la jurisprudence
        (fiqh) de l’école malékite et ses fondements (uṣûl), est chargé de
        rédiger des avis juridiques (fatwâ) en phase avec la méthodologie
        traditionnelle. Habitué à consulter de nombreux savants officiant dans
        les plus nobles institutions du monde musulman d’obédience malékite, ses
        enseignants de la maḥḍara Nubbaghiya (Mauritanie), de Touba (Sénégal),
        ou certains shuyûkh d’Adrâr (Algérie), de la région d’al-Aḥsâ` (Arabie
        Saoudite), du Bahreïn et d'Égypte, il lui est demandé, tout habilité
        qu’il est à cette fonction, d’élargir cet effort dans l’intérêt de la
        communauté francophone. À cet égard, il fait partie d'un groupe de
        travail nommé « al-Nawâzil » aux côtés de certains des plus grands
        savants mauritaniens et crée à leur initiative pour traiter les cas
        nouveaux.
      </p>
    </div>
  </section>

  <section id="ethics" class="section-blue">
    <div>
      <h1>L'éthique de la consultation juridique</h1>
      <p>
        L’Iftâ` est techniquement le fait d’informer d’un statut légal sans
        caractère coercitif (al-ikhbâr bi-ḥukmi charᶜî lâ ᶜalâ wajhi-l-ilzâm),
        d’où notre traduction par consultation juridique. Il s’agit d’une
        relation privilégiée entre un questionneur et une personne, garante du
        savoir religieux, qui concrétise une obligation collective<sup
          id="fnref:1"
        >
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:1')"
            >[1]</a
          ></sup
        >. Deux aspects peuvent être abordés :
      </p>
      <ol>
        <li>
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('mufti')"
            >Les conditions que doit remplir la personne chargée de répondre au
            questionneur</a
          >
        </li>
        <li>
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('mustafti')"
            >Les convenances du questionneur</a
          >
        </li>
      </ol>
    </div>
  </section>

  <section id="mufti">
    <div>
      <h2>
        Les conditions que doit remplir la personne chargée de répondre au
        questionneur
      </h2>
      <p>
        La personne chargée de répondre au questionneur doit remplir plusieurs
        conditions :
      </p>
      <ol>
        <li>
          Etre pubère, doué de raison, probe et non connu pour son dévoiement
          (fisq).
        </li>
        <li>
          Ne pas se précipiter à émettre une réponse mais, au contraire, prendre
          le temps de la réflexion et de l’analyse. S’il connait la réponse, il
          peut répondre aussitôt. Il peut arriver qu’une question reste sans
          réponse. En effet, Ibn Mas’ûd disait : celui qui répond à chacune des
          questions qui lui est posé est un fou
          <sup id="fnref:2"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:2')"
              >[2]</a
            ></sup
          >
          ». On a interrogé l’imam Mâlik sur 48 questions et il a répondu à 32
          d’entre elles : « je ne sais pas ». On lui dit : « mais c’est une
          question simple », il s’est mis en colère et a rétorqué : « il n’y a
          rien de simple en matière de savoir (ᶜilm)<sup id="fnref:3"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:3')"
              >[3]</a
            ></sup
          >
          ». Envisager l’absence de réponse conduit à interroger son statut. A
          Cet égard, al-Laqânî a dit<sup id="fnref:4"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:4')"
              >[4]</a
            ></sup
          >
          : « al-Juzûlî a dit : il est obligatoire au savant de répondre à
          quatre conditions:
        </li>
      </ol>
      <ul>
        <li>que le questionneur interroge sur ce qui lui est obligatoire</li>
        <li>qu’il craigne que le temps du cas en question ne passe</li>
        <li>
          que la personne interrogée connaisse la réponse, soit par son ijtihâd
          s’il est mujtahid soit en transmettant l’avis de celui qu’il suit.
        </li>
        <li>
          que le questionneur soit pubère. Il est à noter qu’une divergence
          existe à propos de cette condition car l’impubère est concernée par la
          prière. Partant de là, certains estiment qu’une réponse doit
          obligatoirement lui être apportée. »
        </li>
      </ul>
      <ol start="3">
        <li>
          Ne pas faire preuve de laxisme dans ses réponses, ni chercher à
          avantager autrui par celle-ci, ou au contraire adopter une position
          rigide dans le but de nuire à quiconque.
        </li>
        <li>
          Il doit répondre lorsqu’il est disposé et non aux prises avec des
          changements d’humeur qui impacteraient son jugement, comme la faim, la
          soif, la colère, la douleur ou lorsqu’il est préoccupé. Son esprit
          doit être entièrement disposé à l’écoute de l’interrogateur
          (mustafti). Si toutefois, il venait à émettre un jugement dans cet
          état, la consultation n’en demeurerait pas moins valide mais,
          assurément, il prend un risque.
        </li>
        <li>
          Ne pas répondre à une question dépendante d’éléments de contexte sans
          le maitriser. Il en est ainsi des consultations relatives aux termes
          utilisés, dans les serments, les mariages et les divorces et de toute
          norme relevant de l’usage et de la coutume. En ce sens, dans certains
          registres, questionner des personnes étrangères au contexte européen,
          qui n’en maitrise ni la langue, ni les codes, ni les coutumes apparait
          comme un non-sens au regard des conditions requises de la part de la
          personne consultée. C’est dans cet esprit qu’ustadh Maxime, outre sa
          formation, lui qui est né et a grandi en France, a été sollicité pour
          cette fonction. La connaissance du contexte inclut l’accès aux
          personnes ressources, et la possibilité de questionner différents
          experts dans les domaines en lien avec la question : alimentation,
          industries, pharmacie, chimie, médecines vétérinaires et autres.
          Al-Qarâfî a dit: « maintenir des statuts basés sur les coutumes alors
          que celles-ci ont changé est contraire au consensus et une ignorance
          dans la religion. Tout ce qui dans la Loi révélée est tributaire de la
          coutume, change de statut lorsqu’elles changent selon ce qu’impliquent
          ces nouvelles habitudes. Nul besoin d’un nouvel ijtihâd émanant du
          muqallid qui exigerait que l’on conditionne par l’aptitude à l’effort
          d’interprétation (ijtihâd), mais plutôt ces règles ont déjà fait
          l’objet d’un ijtihâd de la part des savants qui se sont accordés
          dessus et nous les suivons sans avoir à en refaire un […]. C’est le
          cas notamment pour les testaments, les serments et l’ensemble des
          chapitres du fiqh où la coutume joue un rôle important. Si la coutume
          change, les statuts qui en découlent changent également dans ces
          chapitres […]. Puis il a dit : « selon cette loi, les avis juridiques
          doivent être considérés au quotidien, lorsque la coutume change, on la
          prend en considération et lorsqu’elle n’existe plus, le statut n’a
          plus lieu d’être également. Ne reste pas figé sur les narrations
          transmises dans les livres toute ta vie, mais plutôt, si un homme qui
          ne fait pas partie de ta contrée vient te questionner, ne l‘entraine
          pas vers ta coutume (‘urf) mais questionne-le sur celle de son pays et
          n’émet pas d’avis selon les coutumes (ᶜurf) de ton pays ni ce qui est
          établi dans tes livres, cela est la vérité claire. Le fait de rester
          figés (jumûd) à jamais sur les narrations transmises est un égarement
          (ḍalâl) de la religion et une ignorance des finalités des savants des
          musulmans et des prédécesseurs […] »<sup id="fnref:5"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:5')"
              >[5]</a
            ></sup
          >.
        </li>
        <li>
          Au-delà de la prise en compte de la coutume, il doit également
          chercher à repousser les préjudices (dafᶜ al-mafsada) et à mettre en
          évidence les intérêts (jalb al-maṣlaha)<sup id="fnref:6">
            <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:6')"
              >[6]</a
            ></sup
          >
          comme mentionné en détail dans les ouvrages spécialisés. C’est ainsi
          que toutes les réponses n’ont pas vocation à être rendue publique,
          soucieux de respecter la particularité potentielle de certaines
          consultations.
        </li>
        <li>
          L’imam Mâlik a dit : « il ne convient pas à quelqu’un de se sentir
          compétent en quoi que ce soit avant d’avoir interrogé plus savant que
          lui [qui décrète qu’il l’est]
          <sup id="fnref:7"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:7')"
              >[7]</a
            ></sup
          >
          ». En d’autres termes, la consultation juridique doit être entre les
          mains de personnes autorisées. Or, nous constatons sur internet une
          pratique anarchique de la consultation juridique, faite sous pseudo
          anonyme ou émanant de parfait inconnu, autoproclamé. L’une des
          ambitions de l’Institut Francophone d'Iftaa est justement, en toute
          humilité, de proposer un encadrement, avec l’aval de savants reconnus
          dans différents pays du monde musulman d’obédience malikite. Trop de
          jeunes étudiants, dénués de sagesse, et manifestement des qualités
          nécessaires à cet exercice se sont arrogés cette fonction, créant
          débats stériles, instillant incertitude et confusion dans l’esprit de
          beaucoup de musulmans, à travers des propos inappropriés, cherchant à
          faire étalage de leur culture juridique islamique présumée
          authentique. Il nous faut alerter sur l’obligation pour tout
          questionneur de se diriger vers des gens aptes à la consultation
          <sup id="fnref:8"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:8')"
              >[8]</a
            ></sup
          >. Assurément les pseudos inconnus ne remplissent pas ces conditions.
        </li>
      </ol>
      <p>
        La méthode appliquée à l’IFI sera, tout d’abord, de chercher à bien
        comprendre la question et ses implications, si besoin d’effectuer les
        recherches nécessaires dans les ouvrages de référence, et de consulter
        les spécialistes du domaine concerné (médecine, chimie, etc.) puis
        d’échanger, quand nécessaire, avec nos savants afin de produire une
        réponse finale.
      </p>
    </div>
  </section>

  <div class="section-img">
    <section id="mustafti" class="section-dark-blue-overlay">
      <div>
        <h2>
          Les convenances du questionneur
          <sup id="fnref:9"
            ><a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fn:9')"
              >[9]</a
            ></sup
          >
        </h2>
        <ol>
          <li>
            Il convient qu’il soit poli à l’égard du mufti et qu’il lui témoigne
            du respect.
          </li>
          <li>
            Il doit chercher à exprimer sa question de la manière la plus
            claire, citant tous les détails susceptibles d’impacter la réponse.
          </li>
          <li>
            Il ne dit pas après avoir eu la réponse : « c’est aussi mon avis »
            ou « c’est ce que je pensais » ou « j’ai interrogé quelqu’un d’autre
            qui m’a répondu autre chose ».
          </li>
          <li>
            Il n’est pas séant de demander l’argument (dalîl) de la personne
            consultée. Si vraiment, il veut rasséréner son cœur par l’écoute de
            l’argument, que ce soit l’objet d’une demande ultérieure et après
            avoir accepté la réponse telle quelle.
          </li>
          <li>Il invoque en faveur de la personne consultée.</li>
        </ol>
      </div>
    </section>
  </div>

  <section>
    <div>
      <h1 id="ifi">L'objectif de l’IFI</h1>
      <p>
        Partant d’un vide coupable, l’objectif de l’IFI est d’encadrer cette
        pratique autour de personnes autorisées, en conservant le socle de la
        tradition tout en répondant aux exigences contemporaines. Bien heureux
        celui qui trouvait jadis un juriste compétent au coin de sa rue, ou dans
        la mosquée de son quartier, malheureusement la réalité est toute autre,
        les imams ne sont pas assez formés et quand ils le sont, un tant soi
        peu, ils sont loin de l’Islam traditionnel, parfois même quand ils s’en
        réclament…
      </p>
      <p>
        Nous demandons à Dieu l’assistance et de faire œuvre utile pour la
        communauté francophone.
      </p>
    </div>
  </section>

  <section class="section-blue">
    <div>
      <h1>Recommandations</h1>
      <div class="row">
        <div class="col-lg-4 justify-content-center">
          <app-tazkiya-card [author]="'shaykhShuayb'"></app-tazkiya-card>
        </div>
        <div class="col-lg-4 justify-content-center">
          <app-tazkiya-card [author]="'shaykhAbdallah'"></app-tazkiya-card>
        </div>
        <div class="col-lg-4 justify-content-center">
          <app-tazkiya-card [author]="'shaykhButtar'"></app-tazkiya-card>
        </div>
      </div>
    </div>
  </section>

  <div class="section-img">
    <section id="footnotes" class="section-grey-overlay">
      <div>
        <h2 id="références">Références</h2>
        <p>
          <span id="fn:1">1.</span>
          An-Nawawî, Âdâbu-l-Fatwâ wa-l-muftî wa-l-mustaftî, page 13, édition
          Dâr al-Fikr.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:1')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:2">2.</span>
          An-Nawawî, âdâbu-l-Fatwâ wa-l-muftî wa-l-mustaftî, page 14, édition
          Dâr al-Fikr.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:2')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:4">4.</span>
          Al-Laqânî, Manâr uṣûl al-Fatwâ, page 235.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:4')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:5">5.</span>
          Al-Qarâfî, al-Iḥkâm en réponse à la 39ème question. Al-Hilâlî, Nûr
          al-Baṣar fî charḥ al-Mukhtaṣar, page 270, édition al-Maktaba
          al-ᶜAṣriyya.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:5')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:6">6.</span>
          Ibid, page 270.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:6')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:7">7.</span>
          An-Nawawî, Âdâbu-l-Fatwâ wa-l-muftî wa-l-mustaftî, page 18, édition
          Dâr al-Fikr.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:7')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:8">8.</span>
          Ibid, page 61.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:8')"
            >↩</a
          >
        </p>
        <p>
          <span id="fn:9">9.</span>
          An-Nawawî, Âdâbu-l-Fatwâ wa-l-muftî wa-l-mustaftî, page 83, édition
          Dâr al-Fikr.
          <a [routerLink]="[]" (click)="vpScroller.scrollToAnchor('fnref:9')"
            >↩</a
          >
        </p>
      </div>
    </section>
  </div>
</div>
