<div class="bg-mezquita mb-5">
  <div class="d-flex justify-content-center overlay-primary pt-2">
    <div class="col-lg-9">
      <img class="banner" src="assets/img/ifi_text.png" />
    </div>
  </div>
</div>

<div class="d-flex justify-content-center mb-5">
  <div class="col-lg-9 d-flex flex-column justify-content-center">
    <p class="text-justify home-p">
      L'Institut Francophone d'Iftaa est un service dont l'objectif est de
      fournir des réponses aux différentes questions juridiques que peuvent se
      poser la communauté musulmane francophone (de rite malékite). Cet Institut
      d'Iftaa s'inscrit dans la continuité de nos activités relatives aux
      sciences religieuses (<span
        ><a href="https://institut-asharite.com">Institut Asharite</a></span
      >). La gestion de cet institut et la rédaction des avis sont confiées à
      notre enseignant de jurisprudence malékite, avec l'accord et la
      collaboration de savants malékites traditionnels, reconnus en Mauritanie,
      au Sénégal, en Algérie et ailleurs, qui pourront être consultés afin
      d'échanger et de débattre autour des questions les plus délicates. Vous
      disposez au travers de cet institut, d'un lieu d'accueil privilégié vous
      permettant d'obtenir un avis juridique sur vos questions personnelles du
      quotidien, ainsi que d'un ensemble de publications traitant de thématiques
      plus générales, organisées en catégories. Nous vous remercions de votre
      confiance et restons à votre disposition pour toute proposition ayant pour
      but d'améliorer ce service.
    </p>

    <p class="text-right home-signature">
      Un projet initié par Shaykh AbdAllâh Penot.
    </p>
    <a class="text-right home-link" routerLink="/about">En savoir plus...</a>
  </div>
</div>

<div class="home-search-img">
  <div class="home-search-banner text-light">
    <div class="d-flex py-5 justify-content-center">
      <div class="d-flex flex-column col-md-9">
        <div class="d-flex justify-content-center">
          <p class="home-title">
            Abū al-Dardā` (ASL) a entendu ces propos de l'Envoyé de Dieu -ﷺ- :
          </p>
        </div>
        <div class="d-flex flex-column">
          <p class="d-flex home-hadith justify-content-center">
            “Dieu facilitera l'accès au Paradis à celui qui emprunte une voie
            avec le désir d'acquérir la science. [...]”
          </p>
          <p class="d-flex justify-content-center">
            <i>— Jāmi' at-Tirmidhi 2683</i>
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="col-lg-8 my-3">
            <app-home-search-form
              [clearForm$]="clearSearchForm$"
              (searchFormSubmitted)="onSearchFormSubmitted($event)"
            >
            </app-home-search-form>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-3 btn-container">
          <a routerLink="/question">
            <button class="btn btn-primary">Poser une question</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center">
  <div id="results" class="py-5 col-lg-9">
    <div class="row">
      <div class="col-lg-9 p-3">
        <app-home-search-results
          [fetchedPublications$]="fetchedPublications$"
          [sticky]="!displaySearchResults"
          [hasMore]="hasMore$ | async"
          (viewMoreClick)="onViewMoreClicked(!displaySearchResults)"
        >
        </app-home-search-results>
      </div>
      <div class="col-lg-3 p-3">
        <app-category-cloud
          [categories]="categories$ | async"
          [externalSelectionChanges$]="categorySelection$.asObservable()"
          (itemClicked)="onCategoryChanged($event)"
        ></app-category-cloud>
      </div>
    </div>
  </div>
</div>
