import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

type State = 'done' | 'cancelled';

@Component({
  selector: 'app-donate-feedback',
  templateUrl: './donate-feedback.component.html',
  styleUrls: ['./donate-feedback.component.scss'],
})
export class DonateFeedbackComponent implements OnInit {
  state$ = new Observable<State | undefined>();

  constructor(private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit(): void {
    this.state$ = this._route.queryParams.pipe(
      map((params) => params['state']),
      mergeMap((state) =>
        (state && state === 'done') || state === 'cancelled'
          ? of(state)
          : throwError(`unknown state: ${state}`)
      ),
      catchError((_) => {
        this._router.navigateByUrl('/404');
        return NEVER;
      })
    );
  }
}
