import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Publication } from 'src/app/app.model';

@Component({
  selector: 'app-home-search-results',
  templateUrl: './home-search-results.component.html',
  styleUrls: ['./home-search-results.component.scss'],
})
export class HomeSearchResultsComponent {
  @Input()
  fetchedPublications$!: Observable<Publication[] | undefined>;

  @Input()
  sticky?: boolean;

  @Input()
  hasMore!: boolean | null;

  @Output()
  viewMoreClick = new EventEmitter<void>();

  onViewMoreClicked(): void {
    this.viewMoreClick.emit();
  }
}
