<header>
  <app-navbar #navbar></app-navbar>
</header>
<div [style.height]="gapHeightPx + 'px'"></div>
<div class="container-fluid px-0">
  <div>
    <router-outlet></router-outlet>
  </div>

  <footer>
    <div class="d-flex mt-5 p-0 pb-3">
      <div class="col">
        <p class="my-0 py-0 text-center">
          <a routerLink="/home">Page d'accueil</a> |
          <a routerLink="/about">A propos</a> |
          <a routerLink="/about" fragment="ethics"
            >Ethique de la consultation juridique</a
          >
          |
          <a routerLink="/donate">Nous soutenir</a>
          |
          <a routerLink="/contact">Nous contacter</a>
          <br />
          <a routerLink="/cgu">Conditions générales d'utilisation</a>
        </p>
        <div class="d-flex justify-content-center">
          <a href="https://www.facebook.com/ifi.iftaa" target="_blank">
            <img src="assets/img/fb_logo.png" class="rs-logo" />
          </a>
          <a href="https://twitter.com/ifi_iftaa" target="_blank">
            <img src="assets/img/x_logo.png" class="rs-logo" />
          </a>
        </div>
        <p class="my-0 py-0 text-center">© IFI {{ year }}</p>
      </div>
    </div>
  </footer>
</div>
