import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../service/api.service';
import { ViewportScroller } from '@angular/common';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss'],
})
export class DonateComponent implements AfterViewInit {
  amount = null;
  disableForm = false;

  @ViewChild('donateForm') private _donateForm!: NgForm;
  @ViewChild('errorModal') private errorModalTemplate!: TemplateRef<any>;

  constructor(
    private _api: ApiService,
    private _modalService: NgbModal,
    private _vpScroller: ViewportScroller,
    tags: PageTagsService
  ) {
    tags.handlePage('donate');
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });

    this._donateForm.resetForm();
  }

  submitDonateForm(donateForm: NgForm): void {
    if (donateForm.invalid) {
      donateForm.controls['amountInput'].markAsTouched();
      return;
    }
    this.disableForm = true;
    this._api
      .donate(donateForm.value.amountInput)
      .subscribe(
        (url) => (window.location.href = url),
        this.handleSendError.bind(this)
      );
  }

  private handleSendError(err: any): void {
    console.error(err);
    this._modalService
      .open(this.errorModalTemplate, { size: 'lg' })
      .result.then(
        (_) => (this.disableForm = false),
        (_) => (this.disableForm = false)
      );
  }
}
