import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { Category, Publication } from '../app.model';
import { CategoriesService } from '../service/categories.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';
import { PageTagsService } from '../service/pagetags.service';

@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.component.html',
  styleUrls: ['./publication-detail.component.scss'],
})
export class PublicationDetailComponent implements OnInit, AfterViewInit {
  publication!: Publication;
  publicationCategory!: Category;
  categoryImg!: string;

  categories$: Observable<Category[]>;

  constructor(
    private _api: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _categories: CategoriesService,
    private _vpScroller: ViewportScroller,
    private _tags: PageTagsService
  ) {
    this.categories$ = this._categories.getAll();
  }

  ngOnInit(): void {
    let pubId = Number(this._route.snapshot.paramMap.get('id'));
    this._api.getPublication(pubId).subscribe(
      (pub) => {
        this._tags.handlePage('publication-detail', pub);
        this.publication = pub;
        this.publication.istiftaaBody = this.publication.istiftaaBody.replace(
          /\n/g,
          '<br>'
        );
        this._categories
          .getById(this.publication.categoryId)
          .subscribe((c) => (this.publicationCategory = c), console.error);
        this._categories
          .getImage(this.publication.categoryId, 'large')
          .subscribe((i) => (this.categoryImg = i), console.error);
      },
      (e) => {
        if (
          e instanceof HttpErrorResponse &&
          (<HttpErrorResponse>e).status === 404
        ) {
          this._router.navigateByUrl('/404');
        } else {
          console.error(e);
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => {
      this._vpScroller.setOffset([0, 78]);
      this._vpScroller.scrollToPosition([0, 0]);
    });
  }

  onCategoryChanged(catIds: number[] | null): void {
    this._router.navigate(['/home'], { queryParams: { categoryId: catIds } });
  }
}
