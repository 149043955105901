import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../service/api.service';

type Result = 'NO_TOKEN' | 'FAILURE' | 'SUCCESS';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  confirmResult$!: Observable<Result>;

  constructor(private route: ActivatedRoute, private api: ApiService) {}

  ngOnInit(): void {
    this.confirmResult$ = this.route.queryParamMap.pipe(
      delay(1000),
      map((map) => map.get('token')),
      mergeMap((token) => (token ? of(token) : throwError(<Result>'NO_TOKEN'))),
      mergeMap((token) => this.confirm(<string>token)),
      catchError((err) => of(<Result>err))
    );
  }

  private confirm(token: string): Observable<Result> {
    return this.api.confirmIstiftaa(token).pipe(
      map((_) => <Result>'SUCCESS'),
      catchError((_) => throwError(<Result>'FAILURE'))
    );
  }
}
