import { Component, Input, OnInit } from '@angular/core';

type Author = 'shaykhShuayb' | 'shaykhAbdallah' | 'shaykhButtar';
type AuthorData = {
  name: string;
  city: string;
  picPath: string;
  pdfPath: string;
};

@Component({
  selector: 'app-tazkiya-card',
  template: `
    <div class="d-flex flex-column align-items-center">
      <div class="author-pic d-flex justify-content-center m-3">
        <img src="{{ authorData.get(author)?.picPath }}" />
      </div>
      <div class="d-flex flex-column justify-content-center">
        <p class="author-name mb-0">{{ authorData.get(author)?.name }}</p>
        <p class="author-city text-center">
          {{ authorData.get(author)?.city }}
        </p>
      </div>
      <div class="btn-container">
        <a
          href="{{ authorData.get(author)?.pdfPath }}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button class="btn btn-secondary">Lire</button>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./tazkiya-card.component.scss'],
})
export class TazkiyaCardComponent implements OnInit {
  @Input()
  author!: Author;

  authorData: Map<string, AuthorData> = new Map<Author, AuthorData>([
    [
      'shaykhShuayb',
      {
        name: 'Shaykh Shuayb Kebe',
        city: 'Touba, Sénégal',
        picPath: 'assets/img/img_kebe.png',
        pdfPath: 'assets/docs/ShaykhShuayb.pdf',
      },
    ],
    [
      'shaykhAbdallah',
      {
        name: 'Shaykh AbdAllâh Penot',
        city: 'France',
        picPath: 'assets/img/img_penot.png',
        pdfPath: 'assets/docs/ShaykhAbdallah.pdf',
      },
    ],
    [
      'shaykhButtar',
      {
        name: 'Shaykh Muhammad Buttâr',
        city: 'Nubbâghiyya, Mauritanie',
        picPath: 'assets/img/img_buttar.png',
        pdfPath: 'assets/docs/ShaykhButtar.pdf',
      },
    ],
  ]);

  constructor() {}

  ngOnInit(): void {}
}
