<div class="d-flex justify-content-center bg-primary fixed-top">
  <div class="col-lg-9">
    <nav #navbar class="navbar navbar-expand-md navbar-dark border-0">
      <a class="navbar-brand" routerLink="/home">
        <img src="assets/img/ifi_sm_logo_nt.png" height="53em" />
        <img src="assets/img/ifi_sm_logo_t.png" height="53em" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        [ngbCollapse]="isCollapsed"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              [class.active]="activatedRoute == 'home'"
              routerLink="/home"
              >Accueil</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [class.active]="activatedRoute == 'about'"
              routerLink="/about"
              >L'IFI</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [class.active]="activatedRoute == 'question'"
              routerLink="/question"
              >Poser une question</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [class.active]="activatedRoute == 'donate'"
              routerLink="/donate"
              >Soutenir l'IFI</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
