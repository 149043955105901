<h2 class="font-weight-bold">
  {{ !sticky ? "Résultats de votre recherche" : "Publications à l'affiche" }}
</h2>
<ng-container
  *ngIf="fetchedPublications$ | async as publications; else emptyResults"
>
  <div
    *ngFor="let publication of publications; let i = index"
    [style.background-color]="i % 2 ? '#F8F8F8' : 'white'"
    class="d-flex"
  >
    <app-publication-card
      [publication]="publication"
      class="flex-grow-1"
    ></app-publication-card>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <button
      *ngIf="hasMore"
      class="btn btn-lg btn-secondary"
      (click)="onViewMoreClicked()"
    >
      Voir plus
    </button>
  </div>
</ng-container>
<ng-template #emptyResults>
  <p class="lead text-center">Aucun resultat</p>
</ng-template>
